import React from "react";
import Styles from "./HorizonLine.module.scss";
import {observer} from "mobx-react";


@observer
class HorizonLine extends React.Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        let {color, stroke} = this.props;
        return (
                <div
                    className={`
                    ${Styles.HorizonLine}
                    `}
                    style={{
                        backgroundColor:color,
                        height:stroke,
                }}>

                </div>
        );
    }
}

export default HorizonLine;