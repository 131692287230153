import axios from "axios";
import {isMobile} from "react-device-detect";
import React from "react";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_API_PATH = process.env.REACT_APP_API_PATH;
export const APP_PUBLIC_PATH = process.env.REACT_APP_PUBLIC_BASE_URL;

axios.defaults.baseURL = `${APP_API_PATH}`
axios.defaults.withCredentials = true
axios.defaults.responseType = 'json'


export const IS_MOBILE = isMobile;

/**
 * URL 메뉴 그룹 추출 정규식
 * @type {RegExp}
 */
export const MENU_GROUP_RE = /\/main\/([^\/]+)(?=\/)?/;

export const MODAL_EL = document.getElementById("modal-root");